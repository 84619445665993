import { ref, reactive, computed, h } from 'vue'
import { UserManager, WebStorageStateStore } from 'oidc-client'

export function createAuth(config) {
    config = {
        ...{
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            automaticSilentRenew: true,
            response_type: 'code',
            filterProtocolClaims: true,
        },
        ...config,
    }

    const mgr = new UserManager(config)
    mgr.events.addUserLoaded((newUser) => (user.value = newUser))
    mgr.events.addUserUnloaded(() => (user.value = null))
    mgr.events.addAccessTokenExpired(() => (user.value = null))
    mgr.events.addUserSignedOut(() => (user.value = null))

    const user = ref(null)

    const auth = reactive({
        install,
        user,
        isAuthenticated: computed(() => !!user.value && !user.value.expired),
        accessToken: computed(() => (!!user.value && !user.value.expired ? user.value.access_token : '')),
        useRouter,
        signOut,
    })

    async function install(app) {
        var storedUser = await mgr.getUser()
        if (storedUser && !storedUser.expired) user.value = storedUser
        app.config.globalProperties.$auth = this
    }

    function signOut() {
        return mgr.signoutRedirect()
    }

    function useRouter(router) {
        router.beforeEach(async (to, from, next) => {
            if (to.matched.some((record) => record.meta.requiresAuth)) {
                if (auth.isAuthenticated) {
                    next()
                } else {
                    await mgr.signinRedirect({ state: { to } })
                    next(false)
                }
            } else next()
        })
        router.addRoute({
            path: new URL(config.redirect_uri).pathname,
            component: {
                render: () => h('div'),
                async created() {
                    var response = await mgr.signinRedirectCallback()
                    router.replace(response.state?.to || '/')
                },
            },
        })
        router.addRoute({
            path: new URL(config.silent_redirect_uri).pathname,
            component: {
                render: () => h('div'),
                created() {
                    mgr.signinSilentCallback()
                },
            },
        })
    }

    return auth
}
