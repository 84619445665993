<template>
    <div id="gameSelect">
        <img id="gameIcon"/>
        <h1 id="gameName"></h1>
    </div>
    <div class="container">
        <img src="/logo.png">
        <div class="wrapper">
            <a v-for="(game, index) in games" :key="index" class="a box" @click="this.selectGame(game)">
                <img id="gameIcon" :src="game.icon"/>
                <p class="gameName">{{ game.name.en }}</p>
            </a>
            <router-link to="leaderboards" class="a box">
                <img id="leaderboardIcon" src="/leaderboard.png"/>
                <p class="gameName">Leaderboards</p>
            </router-link>
        </div>
    </div>
</template>

<script>
import axios from 'axios';


export default {
    name: 'HomeView',
    data() {
        return {
            games: [],
        }
    },
    mounted() {
      this.fetchGames();  
    },
    methods: {
        signOut() {
            this.$auth.signOut()
        },
        async fetchGames() {
            var url = `https://api.arcade.unify.fun/client/fetchGames?site=${this.$auth.user.profile.site}`;
            await axios.get(url, {
                headers: {
                    "Authorization": `Bearer ${this.$auth.accessToken}`
                }
            }).then((res) => {
                this.games = res.data;
            }).catch((err) => {
                console.log(err);
            });
        },
        async selectGame(game) {
            console.log(game)
            var gameSelectElement = document.getElementById("gameSelect");
            gameSelectElement.classList.add("gameSelect-finish");
            document.getElementById("gameName").innerText = game.name.en;
            document.getElementById("gameIcon").src = game.icon;
            setTimeout(() => {
                gameSelectElement.classList.remove("gameSelect-finish");
                location.href = game.applicationUrl;
            }, 1500)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#title {
    color: #FFFFFF;
    font-size: 48px;
}

ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
    margin: 25px;
}

img {
    max-width: 60%;
}

.tile {
    height: 50px;
    background-color: #42b983;
}

.wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 75%;
    color: #444;
    overflow: auto;
    overflow-x: hidden;
    max-height: 75vh;
    justify-content: center;
    align-items: center;
}

.box {
    background-image: url("/tile.png");
    background-size: cover;
    color: #fff;
    border-radius: 5px;
    padding: 20px;
    width: 8rem;
    height: 8rem;
    font-size: 1rem;
    text-align: center;
    font-weight: 600;
    transition: 0.5s
}

a {
    text-decoration: none;
}

a:hover {
    margin-top: -2px;
    transition: 0.5s
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#gameSelect {
    z-index: 999;
    position: absolute;
    background-color: #f2c43e;
    height: 100vh;
    width: 100vw;
    top: -6000px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.gameSelect-finish {
    animation: selectGame 0.5s;
    animation-fill-mode: forwards;
}

#gameIcon {
    margin-top: 22px;
    width: 69px;
}

#leaderboardIcon {
    margin-top: 30px;
    margin-bottom: 10px;
    width: 50px;
}

.gameName {
    font-size: 22px;
}

@media screen and (max-width: 1024px) {
    .wrapper {
        margin-top: 150px;
    }
}

@media screen and (max-width: 1280px) {
    .wrapper {
        margin-top: 0px;
        animation: fadeIn-1280 0.5s;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        margin-top: 50px
    }
    to {
        opacity: 1;
        margin-top: 0px
    }
}

@keyframes fadeIn-1280 {
    from {
        opacity: 0;
        margin-top: 150px
    }
    to {
        opacity: 1;
        margin-top: 100px
    }
}

@keyframes fadeIn-1024 {
    from {
        opacity: 0;
        margin-top: 200px
    }
    to {
        opacity: 1;
        margin-top: 150px
    }
}

@keyframes selectGame {
    from {
        top: -6000px;
    }
    to {
        top: 0;
    }
}

</style>