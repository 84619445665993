<template>
    <router-link to="/leaderboards" id="backLink">
        <img src="/left-arrow.png" id="backImg">
    </router-link>
    <div class="container">
        <img src="/logo.png">
        <div class="wrapper">
            <h2>Top 10 Scores - {{ $route.params.game }}</h2>
            <table>
                <thead>
                    <th>#</th>
                    <th>Name</th>
                    <th>Score</th>
                </thead>
                <tr v-for="(ranking, index) in rankings" :key="index">
                    <td></td>
                    <td>{{ranking.userId}}</td>
                    <td>{{ranking.score}}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'GameLeaderboard',
    data() {
        return {
            rankings: []
        }
    },
    mounted() {
      this.fetchRankings()
    },
    methods: {
        async fetchRankings() {
            var game = this.$route.params.game.toLowerCase();
            var url = `https://api.arcade.unify.fun/${game}/top`;
            await axios.get(url, {
                headers: {
                    "Authorization": `Bearer ${this.$auth.accessToken}`
                }
            }).then((res) => {
                this.rankings = res.data;
            }).catch((err) => {
                console.log(err);
            });
        }
    }
}
</script>

<style scoped>
.wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 75%;
    color: #FFFFFF;
    animation: fadeIn 0.5s;
    overflow: auto;
    overflow-x: hidden;
    max-height: 75vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.container {
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
 }
img {
    max-width: 60%;
}

table {
    counter-reset: rowNumber;
    font-size: 24px;
}

td:first-child:before {
    counter-increment: rowNumber;
    content: counter(rowNumber);
}

th, td {
    padding: 0 50px;
    text-align: center;
}

#backImg {
    max-width: 10%;
}

#backLink {
    position: absolute;
    top: 35px;
    left: 33px;
}
</style>