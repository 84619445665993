<template>
    <router-view />
</template>

<script>
export default {
    name: 'App',
}
</script>

<style>

body {
    background: url("/bg1.png")
}

@font-face {
    font-family: 'Quicksand';
    src: url('./assets/Quicksand-VariableFont_wght.ttf');
}

#app {
    font-family: 'Quicksand';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    margin-top: 60px;
}
</style>
