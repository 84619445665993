import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '@/components/HomeView'
import LeaderboardsView from '@/components/LeaderboardsView.vue'
import GameLeaderboard from '@/components/GameLeaderboard.vue'

const routes = [
    {
        path: '/',
        component: HomeView,
        meta: {
            requiresAuth: true
        },
        name: "Unify Arcade Portal"
    },
    {
        path: '/leaderboards',
        component: LeaderboardsView,
        meta: {
            requiresAuth: true
        },
        name: "Unify Arcade Portal | Leaderboards"
    },
    {
        path: '/rankings/:game',
        component: GameLeaderboard,
        meta: {
            requiresAuth: true
        },
        name: "Unify Arcade Portal | Rankings"
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    document.title = to.name;
    next();
});

export default router
