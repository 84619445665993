<template>
    <router-link to="/" id="backLink">
        <img src="/left-arrow.png" id="backImg">
    </router-link>
    <div class="container">
        <img src="/logo.png">
        <div class="wrapper">
            <router-link v-for="(game, index) in games" :key="index" class="gameTile box" :to="{path: `/rankings/${game.name.en}`}">
                <img id="gameIcon" :src="game.icon"/>
                <p class="gameName">{{ game.name.en }}</p>
            </router-link>
        </div>
    </div>
</template>

<style scoped>
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
    margin: 25px;
}

img {
    max-width: 60%;
}

.wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 75%;
    color: #444;
    animation: fadeIn 0.5s;
    overflow: auto;
    overflow-x: hidden;
    max-height: 75vh;
    justify-content: center;
    align-items: center;
}

.box {
    background-image: url("/tile.png");
    background-size: cover;
    color: #fff;
    border-radius: 5px;
    padding: 20px;
    width: 8rem;
    height: 8rem;
    font-size: 1rem;
    text-align: center;
    font-weight: 600;
    transition: 0.5s
}

.gameTile {
    text-decoration: none;
}

.gameTile:hover {
    margin-top: -2px;
    transition: 0.5s
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#gameIcon {
    margin-top: 22px;
    width: 65px;
}

.gameName {
    font-size: 22px;
}

#backImg {
    max-width: 10%;
}

#backLink {
    position: absolute;
    top: 10px;
}
</style>

<script>
import axios from 'axios'

export default {
    name: 'LeaderboardsView',
    data() {
        return {
            games: [],
        }
    },
    mounted() {
        this.fetchGames();
    },
    methods: {
        async fetchGames() {
            var url = `https://api.arcade.unify.fun/client/fetchLeaderboards?site=${this.$auth.user.profile.site}`;
            await axios.get(url, {
                headers: {
                    "Authorization": `Bearer ${this.$auth.accessToken}`
                }
            }).then((res) => {
                this.games = res.data;
            }).catch((err) => {
                console.log(err);
            });
        }
    }
}
</script>